<template>
    <div>
        <el-tiptap v-model="compValue" lang="ru" :extensions="extensions" :charCounterCount="false"></el-tiptap> 
    </div>    
</template>

<script>
import {
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  ElementTiptap,
  TextAlign,
  Blockquote, 
  Code,
  CodeBlock,
  TodoItem,
  TodoList,
  TextColor,
  TextHighlight,
  FormatClear,
  Link,
  Image,
  HorizontalRule,
  History,
  SelectAll
  // Iframe
} from 'element-tiptap';
import 'element-tiptap/lib/index.css';

export default {
  props: {
      value: { type: String, default: () => '' },
  },
  components: {
    'el-tiptap': ElementTiptap,
  },
  computed: {
    compValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  data() {
      return {
      loading: false,
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic({ bubble: true }),
        new Strike(),
        new TextAlign(),
        new Blockquote(),
        new Code(),
        new CodeBlock(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new TodoList(),
        new TodoItem(),
        new HorizontalRule(),
        new TextColor(),
        new TextHighlight(),
        new Link(),
        new Image(),
        // new Iframe(),
        new History(),
        new SelectAll(),
        new FormatClear()
      ]
    };
  }
};
</script>
