<template>
  <div class="news">
      <el-row type="flex" justify="center" style="margin-top: 0px;" class="mb-3">
        <el-col :span="24" class="back_action_panel">
          <router-link :to="{ name: 'AdministrationNews' }">
            <el-button class="pt-0" type="text" icon="el-icon-back">Назад</el-button>
          </router-link>
          <el-button class="back_action_panel--first_element" @click="saveNews" :disabled="loading" type="text">
            <SaveIcon />
            Сохранить
          </el-button>
          <el-button v-if="id" class="pt-0" type="text" icon="el-icon-delete" @click="handleDelete()">Удалить</el-button>
          <el-button class="pt-0" type="text" icon="el-icon-circle-close" @click="handleCancel()">Отменить</el-button>
        </el-col>
      </el-row>
        <el-form :rules="rules" v-loading="loading" ref="newsForm" :model="news" label-width="15rem" :style="{ height: this.cardHeight}">          
            <el-row class="mt-2">
                <el-col :span="16" class="mr-2">
                    <el-form-item label="Заголовок:" prop="header">
                        <el-input v-model="news.header" size="small"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="6" class="ml-auto" style="width:28.75%">
                    <el-form-item label="Дата и время публикации:" prop="notificationTime">
                        <el-date-picker
                        v-model="news.notificationTime"
                        v-mask="'##.##.#### ##:##'"
                        :disabled="loading"
                        type="datetime"
                        placeholder="дд.мм.гггг чч:мм"
                        format="dd.MM.yyyy HH:mm"
                        style="width:100%;"
                        size="small"
                        :picker-options="datePickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="mt-2">
              <el-col :span="23" >
                <el-form-item label="Описание:" prop="description">
                    <rich-text-editor v-model="news.description"/>
                </el-form-item>
              </el-col>
            </el-row>
        </el-form>
  </div>
</template>

<script>

import cloneDeep from 'lodash.clonedeep';
import newsApi from '@/api/news';
import SaveIcon from '@/assets/icons/save.svg';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import tableResize from '@/mixins/tableResize.js';
import RichTextEditor from '@/components/Ui/RichTextEditor';

export default {
  name: 'AdministrtionNewsItem',
  props: ['id'],
  mixins: [formatters, dateHelpers, tableResize],
  components: {
    SaveIcon, RichTextEditor
  },
  data() {
    const newsTimeValidation = (rule, value, callback) => {
        if (this.news.notificationTime === null) {
          return callback(new Error('Поле обязательно для заполнения'));
        } else {
          return callback();
        }
      };

    return {
      loading: false,
      news: {
        notificationTime: '',
        header: '',
        description: ''
      },
      rules: {
        notificationTime: [
          { validator: newsTimeValidation, trigger: 'blur', required: true }
        ], 
        header: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ], 
        description: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
      },
    };
  },
  async created() {
    this.cardHeight = this.getCardHeight();
    if (this.id) {
      this.loading = true;
      await this.getNews(this.id).then(() => {
        this.loading = false;
      });
    } else {
      this.news.notificationTime = await this.getNow();
    }
  },
  methods: {
    async getNews(id) {
      await newsApi.getNewsItem(id).then((response) => {
        this.news = response.data;
        this.news.notificationTime = this.dateGetter(this.news.notificationTime);     
        this.cardHeight = this.getCardHeight();
      });
    },    
    async saveNews() {
      const valid = await this.validateForm();
      if (!valid) return;

      this.loading = true;

      if (this.news.id == null) {
        await newsApi.createNews(this.getSaveable()).then(async (response) => {
          // this.$router.push({ name: 'AdministrtionNewsItem', params: { id: response.data } }).then(() => null);
          await this.getNews(response.data);
          this.loading = false;
          this.$router.push({ name: 'AdministrationNews' });
        });
      } else {
        this.loading = true;
        await newsApi.updateNews(this.getSaveable()).then(() => {
          this.loading = false;
          this.$router.push({ name: 'AdministrationNews' });
        });
      }
    },
    getSaveable() {
      const result = cloneDeep(this.news);
      result.notificationTime = this.dateSetter(result.notificationTime);
      return result;
    },

    async handleDelete() {
      this.$confirm('Вы действительно хотите удалить запись?', 'Подтверждение', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        this.loading = true;
        await newsApi.deleteNews(this.news.id);
        this.$router.push({ name: 'AdministrationNews' });
        this.loading = false;
      }).catch(() => { });
    },
    async handleCancel() {
      if (!this.news.id) {
        this.$router.push({ name: 'AdministrationNews' });
      } else {
        this.loading = true;
        await this.getNews(this.news.id).then(() => { this.loading = false; });
      }
    },
    async validateForm() {
      const valid = await this.$refs.newsForm.validate();
      return valid;
    },
  }
};
</script>

<style scoped lang="scss">

button.el-button.el-button--text:first-child {
  margin-left: 0px;
}
.el-col>span {
  font-size: 12px;
}
.ml-6 {
    margin-left: calc(5rem - 1vw)!important;
}
.el-form {
  overflow: auto;
}
</style>
